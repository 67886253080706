* {
  margin: 0;
  padding: 0;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  cursor: url(Arrow.svg), auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

body::-webkit-scrollbar {
  display: none;
}

img {
  user-select: none;
  -webkit-user-drag: none;
}

button {
  cursor: url(Arrow.svg), auto;
}

.first_grid {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0 !important;
}

.webgl {
  outline: none;
  cursor: url(Arrow.png), auto;
}

.sound_on {
  position: absolute;
  width: 50px;
  left: 45px;
  bottom: 45px;
}

.sroll_button {
  position: absolute;
  width: 50px;
  right: 45px;
  bottom: 45px;
}

.explore_text {
  position: absolute;
  font-family: 'Roboto';
  font-size: 1.7vw;
  font-weight: 450;
  color: white;
  letter-spacing: 0.08vw;
}

.explore_icon {
  width: 3vw;
  position: absolute;
  top: 45%;
  left: 57%;
  animation: click linear forwards infinite;
  animation-duration: 1s;
}
@keyframes click {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

/* ----------------------------------------------------------------------- */
.second_grid {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 100vh;
}

.second_grid_contentBox {
  height: 95%;
  width: 97%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.second_grid_sound {
  position: absolute;
  width: 50px;
  left: 45px;
  top: 45px;
}
.second_grid_scroll {
  position: absolute;
  width: 50px;
  right: 45px;
  top: 45px;
  transform: rotate(180deg);
}

.second_grid_topCatLogo {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.second_grid_topCatLogo img {
  height: 100%;
}

.second_grid_aboutUsLogo {

  margin-top: 1vh;
  width: 100%;
  height: 8%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.second_grid_aboutUsLogo img {
  height: 65%;
}

.second_grid_big_title {
  width: 100%;
  height: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.second_grid_big_title h1 {
  color: white;
  font-size: 2.25vw;
  font-family: 'CodePro_NormalLC';
  font-weight: 400;
}
.second_grid_big_title p {
  color: #6f37e4;
  font-size: 1.5vw;
  font-family: 'CodePro_NormalLC';
  font-weight: 400;
  text-align: center;
}

.second_grid_email_container {
  height: 20%;
  width: 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: #7e37e3;
  font-family: 'CodePro_NormalLC';
  font-weight: 400;
  text-align: center;
}
.second_grid_email {
  width: 6vw;
}
.second_grid_font2 {
  margin-top: 1vh;
  font-size: 1.4vw;
}
.second_grid_font2_sub {
  font-size: 1.1vw;
}
.second_grid_emailAddress {
  text-decoration:none;
  color: white;
  font-size: 1.4vw;
}

.second_grid_socials_box {
  height: 15%;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.second_grid_socials_box img {
  height: 40%;
}

.second_grid_font3 {
  width: 50%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7e37e3;
  font-family: 'CodePro_NormalLC';
  font-weight: 400;
  text-align: center;
  font-size: 0.8vw;
}

/* ----------------------------------------------------------------------- */
.load_container {
  height: 100vh;
  width: 100vw;
  background-color: white;
  position: fixed;
  z-index: 90;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform-origin: bottom;
  animation-timing-function: linear;
}

.load_container p {
  color: rgb(167, 167, 167);
  font-size: 2vw;
  font-weight: 700;
  position: absolute;
  top: 55%;
  font-family: 'Roboto';
  font-weight: 900;
}

.load_container_gifBox {
  position: relative;
  height: 5vw;
  width: 100%;
  display: none;
}

.load_container_advent {
  position: absolute;
  left: 2%;
  height: 100%;
}

.load_container_sigh {
  position: absolute;
  left: 2%;
  height: 100%;
  display: none;
}

.load_container_walk {
  position: absolute;
  left: 2%;
  height: 100%;
  display: none;
}

.load_container_drag {
  position: fixed;
  left: 46%;
  top: 0;
  height: 7vw;
  display: none;
  animation: drag linear;
  animation-duration: 1s;
  z-index: 99;
}
@keyframes drag {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100vh);
  }
}

.load_container_footer {
  position: absolute;
  height: 3vw;
  width: 8vw;
  bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  color: rgb(167, 167, 167);
  font-size: 0.7vw;
  font-weight: 700;
}

/* ----------------------------------------------------------------------- */

@media screen and (max-width: 600px) {
  .sound_on {
    width: 30px;
    left: 20px;
    bottom: 20px;
  }
  .sroll_button {
    position: absolute;
    width: 30px;
    right: 20px;
    bottom: 20px;
  }

  .second_grid_sound {
    width: 30px;
    left: 20px;
    top: 20px;
  }
  .second_grid_scroll {
    width: 30px;
    right: 20px;
    top: 20px;
    z-index: 99;
    transform: rotate(180deg);
  }

  .second_grid_topCatLogo {
    width: 100%;
    height: 12%;
  }

  .second_grid_aboutUsLogo {
    width: 100%;
    height: 4%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .second_grid_big_title {
    width: 95%;
    height: 25%;
    text-align: center;
    margin-top: 14vh;
  }
  .second_grid_big_title h1 {
    font-size: 2.5vh;
  }
  .second_grid_big_title p {
    font-size: 1.6vh;
    position: relative;
    top: 5%;
  }

  .second_grid_email_container {
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6vh;
  }
  .second_grid_email {
    width: unset;
    height: 4vh;
  }
  .second_grid_font2 {
    font-size: 1.8vh;
  }
  .second_grid_font2_sub {
    font-size: 1.4vh;
  }
  .second_grid_emailAddress {
    color: white;
    font-size: 1.65vh;
  }

  .second_grid_socials_box {
    height: 10%;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .second_grid_socials_box img {
    height: 30%;
  }

  .second_grid_font3 {
    width: 90%;
    height: 5%;
    font-size: 1.2vh;
  }

  .load_container p {
    font-size: 4vw;
    top: 52%;
  }

  .load_container_footer {
    height: 20vw;
    width: 50vw;
    font-size: 3vw;
  }


  .load_container_advent {
    position: absolute;
    top: -3vh;
    left: 2%;
    height: 200%;
  }

  .load_container_sigh {
    position: absolute;
    top: -3vh;
    left: 2%;
    height: 200%;
    display: none;
  }

  .load_container_walk {
    top: -3vh;
    position: absolute;
    left: 2%;
    height: 200%;
    display: none;
  }

  .load_container_drag {
    position: fixed;
    left: 46%;
    /*height: 200%;*/
    top: 0;
    height: 14vw;
    display: none;
    animation: drag linear;
    animation-duration: 1s;
    z-index: 99;
  }
}

/* ----------------------------------------------------------------------- */

/* Fonts */

@font-face {
  font-family: 'CodePro_Black';
  src: url('./assets/Fonts/CodePro-Black.woff') format('woff');
}

@font-face {
  font-family: 'CodePro_BlackLC';
  src: url('./assets/Fonts/CodePro-BlackLC.woff') format('woff');
}

@font-face {
  font-family: 'CodePro_Bold';
  src: url('./assets/Fonts/CodePro-Bold.woff') format('woff');
}

@font-face {
  font-family: 'CodePro_BoldLC';
  src: url('./assets/Fonts/CodePro-BoldLC.woff') format('woff');
}

@font-face {
  font-family: 'CodePro_Light';
  src: url('./assets/Fonts/CodePro-Light.woff') format('woff');
}

@font-face {
  font-family: 'CodePro_LightLC';
  src: url('./assets/Fonts/CodePro-LightLC.woff') format('woff');
}

@font-face {
  font-family: 'CodePro_Normal';
  src: url('./assets/Fonts/CodePro-Normal.woff') format('woff');
}

@font-face {
  font-family: 'CodePro_NormalLC';
  src: url('./assets/Fonts/CodePro-NormalLC.woff') format('woff');
}
